import _ from 'lodash'
import { IController } from '../controllers/controllers'
import { DOMAIN_REGEX } from '../services/constants'
import { siteStore } from '../stores/site-store'

const isUrlInput = (field) => _.get(field, 'inputType') == 'url'

const domainRegex = new RegExp(DOMAIN_REGEX, 'i')

export const getValidURL = (input: string): string => {
  const value = input.trim()
  const validProtocol = ['http', 'ftp']
  const valueStartsWithProtocol = !!validProtocol.find((p) => value.startsWith(p))
  if (valueStartsWithProtocol) {
    return value
  } else {
    const valueMightBeDomain = domainRegex.test(value)
    return valueMightBeDomain ? `https://${value}` : value
  }
}

export const registerURLValidation = async (
  _controller: IController,
  components: WixCodeField[],
) => {
  const urlFields = _.filter(components, isUrlInput)

  if (
    urlFields.length === 0 ||
    !(await siteStore.isEnabledAsync('specs.crm.FormsViewerUrlValidation'))
  ) {
    return
  }

  urlFields.forEach((url) => {
    url.onBlur && url.onBlur((e) => !url.valid && (url.value = getValidURL(e.target.value)))
  })
}
