import _ from 'lodash'
import { Attachment, getFieldValue, createFieldDto } from '../field-dto/field-dto'
import { submitUtils } from '../submit-utils'
import {
  FIELDS_ROLES,
  ROLE_FORM,
  ROLE_MESSAGE,
  ROLE_DOWNLOAD_MESSAGE,
  FIELDS,
  ROLE_SUBMIT_BUTTON,
  AUTOFILL_MEMBER_EMAIL_ROLE,
  ROLE_LIMIT_MESSAGE,
} from '../../constants/roles'
import { FormsFieldPreset, SuccessActionTypes, SecondsToResetDefaults } from '@wix/forms-common'
import { isNotEmptyEmailId } from '../utils/utils'
import { EmailConfig, Field, SubmitFormResponse, SubmitFormRequest } from '../../types/domain-types'
import { siteStore } from '../stores/site-store'
import {
  isUploadButton,
  isRadioGroup,
  isCaptchaField,
  replaceMessageInnerText,
  toMiliseconds,
  getBaseUrl,
  setFieldValue,
  isComplexAddress,
} from '../viewer-utils'
import { post } from '../services/fetch-utils'
import { registerBehaviors } from '../behaviors'
import { IController } from './controllers'
import { ClientCaptchaRequiredError, ErrorName, MembersAutofillError } from '../errors'
import { sanitizePII } from '@wix/bi-logger-sanitizer/dist/src/lib/sanitizers'

export class BaseController implements IController {
  protected attachments: { [uniqueId: string]: Attachment }
  protected fields: any[]
  protected _stopSubmitButtonLoadingState: () => void

  public $w
  public helpers: { wixLocation; wixSite; wixSeo; wixWindow; wixPay; wixUsers, wixAuthentication }
  public formId: string
  public controllerSettings: ControllerSettings
  public initialFields: { uniqueId; value }[]
  public $form: any
  public $message: any
  public $submitButton: any

  constructor(
    {
      $w,
      formId,
      controllerSettings,
    }: {
      $w
      formId: string
      controllerSettings: ControllerSettings
    },
    { wixLocation, wixSite, wixSeo, wixWindow, wixPay, wixUsers, wixAuthentication },
  ) {
    this.$w = $w
    this.formId = formId
    this.controllerSettings = controllerSettings
    this.helpers = { wixLocation, wixSite, wixSeo, wixWindow, wixPay, wixUsers, wixAuthentication }

    this._init()
  }

  protected _init() {
    this.$form = _.get(this.$w(`@${ROLE_FORM}`), '[0]')
    const successMessage = this.$w(`@${ROLE_MESSAGE}`)
    const downloadMessage = this.$w(`@${ROLE_DOWNLOAD_MESSAGE}`)
    const limitMessage = this.$w(`@${ROLE_LIMIT_MESSAGE}`)

    if (_.get(successMessage, 'hide')) {
      successMessage.hide()
      this.$message = successMessage
    }

    if (_.get(downloadMessage, 'hide')) {
      downloadMessage.hide()
      this.$message = downloadMessage
    }

    if (_.get(limitMessage, 'collapse')) {
      limitMessage.collapse()
    }

    this.$submitButton = this.$w(`@${ROLE_SUBMIT_BUTTON}`)
    this.fields = this.getFieldsByRoles(FIELDS_ROLES)
    this.attachments = {}
    this.initialFields = this.fields.map(({ uniqueId, value }) => ({ uniqueId, value }))

    this._registerNumberInputValidation()
    this._registerMembersAutofill()

    const allComponents = _.compact([...this.fields, _.first(this.$submitButton)])
    registerBehaviors(this, allComponents)
  }

  get limitMessage() {
    return this.$w(`@${ROLE_LIMIT_MESSAGE}`)
  }

  async formReachLimit() {
    const shouldCollapse = _.filter(
      this.$form.children,
      (element) => element.id !== this.limitMessage.id,
    )
    await Promise.all([
      ...shouldCollapse.map((ele) => ele.collapse()),
      _.get(this.limitMessage, 'expand') ? this.limitMessage.expand() : Promise.resolve(),
    ])
  }

  onLimitError() {
    if (_.get(this.limitMessage, 'expand')) {
      return this.limitMessage.expand()
    }
  }

  protected get isRegistrationForm() {
    return false
  }

  public getFields() {
    if (this.isRegistrationForm) {
      return this.getFieldsByRoles(FIELDS_ROLES)
    }

    return this.fields
  }

  public getFieldsByRoles(roles) {
    const fields = _.compact(
      roles.reduce((res, roleField) => res.concat(this.$w(`@${roleField}`)), []),
    )
    return _.uniqBy(fields, (field: { uniqueId: string }) => field.uniqueId)
  }

  public getNumOfAttachments() {
    return _.filter(this.fields, (field) => isUploadButton(field) && field.value.length > 0).length
  }

  public async getAttachments() {
    const fieldsWithoutAttachments = this.getFields()
    return [
      ...(await submitUtils.getAttachments(fieldsWithoutAttachments)),
      ...(await submitUtils.getSignatureAttachments({
        currentFields: fieldsWithoutAttachments,
        formId: this.formId,
      })),
    ]
  }

  private _registerNumberInputValidation() {
    const fields = this.fields.filter((field) => field.role === FIELDS.ROLE_FIELD_TEXT)
    const numbers = _.filter(fields, (field) => _.get(field, 'inputType') == 'number')

    _.forEach(numbers, (number) => {
      if (number.onBlur) {
        number.onBlur((e) => {
          number.value = e.target.value
        })
      }
    })
  }

  _logFields(message, fields) {
    try {
      const parsedFields = _.map(fields, (field) => JSON.stringify(field))
      siteStore.captureBreadcrumb({
        message,
        category: 'validateFields',
        data: {
          fields: parsedFields,
          parsedFieldsSize: _.size(parsedFields),
        },
      })
    } catch (err) {
      siteStore.captureException(new Error('Failed to log fields data'), {
        extra: { err },
      })
    }
  }

  _logField(field) {
    try {
      siteStore.captureBreadcrumb({
        message: 'field data',
        category: 'validateFields',
        data: {
          id: field.id,
          required: _.toString(field.required),
          value: sanitizePII(field.value),
          valid: _.toString(field.valid),
          collapsed: _.toString(field.collapsed),
        },
      })
    } catch (err) {
      siteStore.captureException(new Error('Failed to log field data'), {
        extra: { err },
      })
    }
  }

  public validateFields(fields: any[]): any[] {
    // this._logFields('fields', fields)

    const fieldsToTestValidity = _.filter(fields, (field) => !field.collapsed)

    const rejected = _.reject(fieldsToTestValidity, (field) => {
      // this._logField(field)

      if (isRadioGroup(field)) {
        // TODO - waiting for full fix for radioGroup
        return !field.required || field.value.length > 0
      }

      if (isCaptchaField(field)) {
        return !_.isEmpty(field.token)
      }

      if (isUploadButton(field)) {
        if (!field.validity.fileNotUploaded || (field.required && field.value.length === 0)) {
          return field.valid
        }
        return true
      }

      if ('valid' in field) {
        return field.valid
      }

      return true
    })

    this._logFields('rejected', rejected)

    return rejected
  }

  public async logSubmission({ fields }) {
    if (!this.isRegistrationForm) {
      return
    }

    if (_.size(fields) > 0) {
      return
    }

    try {
      siteStore.captureException(new Error('Missing registration form fields data'), {
        extra: {
          currentPage: this.helpers.wixSite.currentPage,
          formId: this.formId,
          connectionConfig: _.get(this.$form, 'connectionConfig'),
        },
        tags: { preset: _.get(this.$form, 'connectionConfig.preset') },
      })
    } catch (err) {
      siteStore.captureException(new Error('Failed to log submission'), {
        extra: { err },
      })
    }
  }

  public logEmailPatternException(fields) {
    try {
      const emailPattern = new RegExp(
        /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])/,
      )

      _.forEach(fields, (field) => {
        if (field.connectionConfig.fieldType === 'email') {
          if (!_.isEmpty(field.value)) {
            const isEmail = emailPattern.test(field.value)

            if (!isEmail) {
              siteStore.captureException(new Error('Failed to validate email pattern'))
            }
          }
        }
      })
    } catch (err) {
      siteStore.captureException(new Error('Failed to log email pattern validation'), {
        extra: { err },
      })
    }
  }

  public logFieldValidationMismatch(fields) {
    try {
      const mismatchField = _.find(
        fields,
        (field) => field.required && field.value === '' && field.valid,
      )
      if (mismatchField) {
        siteStore.captureException(new Error('Found field validation mismatch'), {
          extra: {
            currentPage: this.helpers.wixSite.currentPage,
            formId: this.formId,
            connectionConfig: _.get(this.$form, 'connectionConfig'),
          },
          tags: {
            preset: _.get(this.$form, 'connectionConfig.preset'),
            pageType: this.helpers.wixSite.currentPage.type,
          },
        })
      }
    } catch (err) {
      siteStore.captureException(new Error('Failed to log field validation mismatch'), {
        extra: { err },
      })
    }
  }

  private async _execute({ attachments, fields, captchaToken = undefined }) {
    try {
      return await sendActivity(this.$w, {
        attachments,
        fields,
        wixWindow: this.helpers.wixWindow,
        formId: this.formId,
        captchaToken,
      })
    } catch (err) {
      const errorName = _.get(err, 'name')
      if (errorName === ErrorName.CaptchaRequiredError) {
        const token = await this.openCaptchaChallenge('server')
        if (token) {
          return this._execute({ attachments, fields, captchaToken: token })
        }
      }

      throw err
    }
  }

  public async openCaptchaChallenge(reason) {
    const biData = { origin: 'web', form_comp_id: this.formId }
    siteStore.log({ evid: 1453, reason, ...biData })
    const token = await this.helpers.wixAuthentication.openCaptchaChallenge()
    siteStore.log({ evid: 1454, reason: !!token ? 'resolved' : 'manually closed', ...biData })
    return token
  }

  public async execute({ attachments, fields }): Promise<SubmitFormResponse> {
    this.logFieldValidationMismatch(fields)
    this.logSubmission({ fields })

    const hasCaptchaField = !!_.find(fields, isCaptchaField)

    let captchaToken

    if (!hasCaptchaField) {
      if (siteStore.isEnabled('specs.crm.FormsViewerShowChallenge')) {
        const hasOverrides = !!_.get(siteStore.wixApi, 'location.query.viewerPlatformOverrides')
        const forceCaptchaChallenge = !!_.get(siteStore.wixApi, 'location.query.forceChallenge')
        const isBot = !!_.get(siteStore.platformServices, 'bi.isjp')

        if (!hasOverrides || forceCaptchaChallenge) {
          if (isBot) {
            captchaToken = await this.openCaptchaChallenge('client')

            if (!captchaToken) {
              throw new ClientCaptchaRequiredError()
            }
          }
        }
      }
    }

    const response = await this._execute({ attachments, fields, captchaToken })

    this.logEmailPatternException(fields) // doing after submit to log submissionId

    return response
  }

  async postSubmission() {
    const { secondsToResetForm, successActionType, successLinkValue } = this.$form.connectionConfig

    switch (successActionType) {
      case SuccessActionTypes.LINK:
      case SuccessActionTypes.EXTERNAL_LINK:
        setTimeout(
          () => this.helpers.wixLocation.to(siteStore.platformApi.links.toUrl(successLinkValue)),
          100,
        )
        return Promise.resolve()

      case SuccessActionTypes.DOWNLOAD_DOCUMENT:
        if (_.get(this.$message, 'html', undefined) === undefined) {
          return Promise.resolve()
        }
        replaceMessageInnerText(
          this.$message,
          (innerText) =>
            `<a href="${siteStore.platformApi.links.toUrl(
              successLinkValue,
            )}" target="_blank" role="alert">${innerText}</a>`,
        )
        this.$message.show()
        return Promise.resolve()

      default:
        const hasMessageContent = _.get(this.$message, 'html', undefined) !== undefined
        const timedMessage =
          hasMessageContent &&
          secondsToResetForm >= SecondsToResetDefaults.MIN &&
          secondsToResetForm <= SecondsToResetDefaults.MAX
        const previousMessage: string = timedMessage && this.$message.html

        if (hasMessageContent) {
          replaceMessageInnerText(
            this.$message,
            (innerText) => `<span role="alert">${innerText}</span>`,
          )
          this.$message.show()
        }

        return timedMessage
          ? new Promise((resolve) =>
              setTimeout(() => {
                this.$message.html = previousMessage
                resolve(this.$message.hide())
              }, toMiliseconds(secondsToResetForm)),
            )
          : Promise.resolve()
    }
  }

  private _registerMembersAutofill = (): void => {
    const { wixUsers } = this.helpers
    const autofillField = _.get(this.$w(`@${AUTOFILL_MEMBER_EMAIL_ROLE}`), '[0]')

    if (autofillField) {
      wixUsers.onLogin((_user) => {
        this._setupMembersAutofill(autofillField)
      })

      this._setupMembersAutofill(autofillField)
    }
  }

  private _setupMembersAutofill = async (autofillField): Promise<void> => {
    const { wixUsers } = this.helpers

    if (wixUsers.currentUser.loggedIn) {
      let userEmail

      try {
        userEmail = await wixUsers.currentUser.getEmail()

        if (!userEmail) {
          throw new Error(`User email is invalid: ${userEmail}`)
        }
      } catch (err) {
        siteStore.captureException(new MembersAutofillError(err, 'Fetch of user email failed'))
        return
      }

      const isEditable = _.get(autofillField, 'connectionConfig.isEditable')

      setFieldValue({ field: autofillField, value: userEmail })
      this._setInitialFieldAt({
        uniqueId: autofillField.uniqueId,
        value: userEmail,
      })

      if (!isEditable) {
        autofillField.readOnly = true
      }
    }
  }

  private _setInitialFieldAt({ uniqueId, value }: { uniqueId: number; value: any }): void {
    this.initialFields = this.initialFields.map((field) => {
      if (uniqueId === field.uniqueId) {
        return { ...field, value }
      } else {
        return field
      }
    })
  }

  public getFieldsByRole(role: string) {
    return this.$w(`@${role}`) || []
  }

  public startSubmitButtonLoadingState(): void {
    if (!this._stopSubmitButtonLoadingState && this.$submitButton) {
      this._stopSubmitButtonLoadingState = startButtonLoadingState({
        button: this.$submitButton,
        speed: 200,
        numDots: 7,
      })
    }
  }

  public stopSubmitButtonLoadingState(): void {
    if (this._stopSubmitButtonLoadingState) {
      this._stopSubmitButtonLoadingState()
      this._stopSubmitButtonLoadingState = undefined
    }
  }
}

const getRecipients = (emailIds: string[]) => {
  const sendToOwner: boolean = _.isEmpty(emailIds[0])
  const actualEmailIds: string[] = emailIds.filter(isNotEmptyEmailId)

  return { sendToOwner, emailIds: actualEmailIds }
}

const createEmailConfig = ({
  emailIds,
  selectedSiteUsersIds,
  inboxOptOut,
}: {
  emailIds: string[]
  selectedSiteUsersIds?: string[]
  inboxOptOut?: boolean
}): EmailConfig => {
  const recipients = getRecipients(emailIds)

  if (!_.isBoolean(inboxOptOut) || inboxOptOut) {
    if (recipients.sendToOwner) {
      return {
        sendToOwnerAndEmails: {
          emailIds: [...recipients.emailIds],
        },
      }
    }

    return {
      sendToEmails: {
        emailIds: [...recipients.emailIds],
      },
    }
  } else {
    if (!selectedSiteUsersIds) {
      if (recipients.sendToOwner) {
        return {
          sendToOwner: {},
        }
      }
    }

    return {
      sendToContributors: {
        userIds: selectedSiteUsersIds || [],
      },
    }
  }
}

const FILTERED_FIELDS = [FormsFieldPreset.GENERAL_RECAPTCHA]

const createFieldsDto = ({ fields, attachments, options }) => {
  const fieldsDto = []

  const validFields = _.filter(
    fields,
    (field) => !_.includes(FILTERED_FIELDS, _.get(field, 'connectionConfig.fieldType')),
  )

  _.forEach(validFields, (field: WixCodeField) => {
    let fieldDto: Field
    if (isComplexAddress(field)) {
      const { innerFields } = field

      innerFields.forEach((innerField: WixCodeField) => {
        fieldDto = createFieldDto({ field: innerField, attachments, options })
        fieldsDto.push(fieldDto)
      })
    } else {
      fieldDto = createFieldDto({ field, attachments, options })
      fieldsDto.push(fieldDto)
    }
  })

  return fieldsDto
}

const enrichPayloadWithSecurityToken = ({ token, payload }) => {
  payload.security = { captcha: token }
}

const enrichPayloadWithCaptchaField = ({ $w, payload }) => {
  const captchaField = $w(`@${FIELDS.ROLE_FIELD_RECAPTCHA}`)

  if (captchaField.length > 0) {
    const token = getFieldValue(captchaField)
    enrichPayloadWithSecurityToken({ token, payload })
  }
}

const enrichPayloadWithPaymentData = ({ $w, selectedPaymentOption, payload }) => {
  // TODO: move PAYMENT_OPTIONS to forms-common and use it here
  let selectedItems = []

  switch (selectedPaymentOption) {
    case 'list':
      const itemsListPaymentFields = $w(`@${FIELDS.ROLE_FIELD_ITEMS_LIST}`)
      if (itemsListPaymentFields.length > 0) {
        selectedItems = _.compact(
          _.map(itemsListPaymentFields, (field) =>
            !_.isEmpty(field.value) ? { itemId: field.value } : null,
          ),
        )
      }
      break
    case 'custom':
      const customAmountPaymentFields = $w(`@${FIELDS.ROLE_FIELD_CUSTOM_AMOUNT}`)
      if (customAmountPaymentFields.length > 0) {
        selectedItems = _.compact(
          _.map(customAmountPaymentFields, (field) =>
            !_.isEmpty(field.value)
              ? {
                  itemId: field.connectionConfig.productId,
                  price: field.value,
                }
              : null,
          ),
        )
      }
      break
  }

  if (_.size(selectedItems) > 0) {
    payload.paymentFormDetails = {
      selectedItems,
    }
  }
}

const sendActivity = async (
  $w,
  { attachments, fields, wixWindow, formId, captchaToken = undefined },
) => {
  siteStore.interactionStarted('submission')

  const form = $w(`@${ROLE_FORM}`)
  const {
    emailId,
    secondEmailId,
    emailIds,
    labels,
    formName = '',
    selectedSiteUsersIds,
    inboxOptOut,
    doubleOptIn,
    selectedPaymentOption = 'single',
  } = form.connectionConfig

  const fieldsDto: Field[] = createFieldsDto({ fields, attachments, options: { doubleOptIn } })
  const emailConfig: EmailConfig = createEmailConfig({
    emailIds: emailIds || [emailId, secondEmailId],
    selectedSiteUsersIds,
    inboxOptOut,
  })

  const payload: SubmitFormRequest = {
    formProperties: {
      formName,
      formId,
    },
    emailConfig,
    viewMode: wixWindow.viewMode,
    fields: fieldsDto,
    labelIds: _.compact(labels),
  }

  enrichPayloadWithCaptchaField({ $w, payload })

  if (captchaToken) {
    enrichPayloadWithSecurityToken({ token: captchaToken, payload })
  }

  enrichPayloadWithPaymentData({ $w, selectedPaymentOption, payload })

  const shouldDisableRetry = _.has(payload, 'security')
  const baseUrl = getBaseUrl()

  const response = await post<SubmitFormResponse>(
    baseUrl,
    '_api/wix-forms/v1/submit-form',
    payload,
    shouldDisableRetry,
  )

  siteStore.interactionEnded('submission')

  return response
}

const startButtonLoadingState = ({
  button,
  speed,
  numDots,
}: {
  button: any
  speed: number
  numDots: number
}): (() => void) => {
  const initialButtonLabel = button.label
  let numOfDotsPrinted = 0
  button.label = createLoadingDots({ totalNumDots: numDots, currentNumDots: numOfDotsPrinted })

  const workId = setInterval(() => {
    numOfDotsPrinted = numOfDotsPrinted === numDots ? 0 : numOfDotsPrinted + 1
    button.label = createLoadingDots({ totalNumDots: numDots, currentNumDots: numOfDotsPrinted })
  }, speed)

  const stopLoadingState = () => {
    clearInterval(workId)
    button.label = initialButtonLabel
  }

  return stopLoadingState
}

const createLoadingDots = ({ totalNumDots, currentNumDots }) =>
  `${_.repeat('.', currentNumDots)}${_.repeat('\xa0', totalNumDots - currentNumDots)}`
